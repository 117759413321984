<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title color="white">Aggiorna prenotazione</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <form @submit.prevent="modificaPrenotazione()">
          <ion-list class="fields">
            <ion-item lines="none" class="ion-no-padding">
              <div class="recap_info">
                <div class="field_info">
                  <div class="field_title">Data ed ora inizio</div>
                  <div class="value">
                    {{ dateFormatWithMinutes(prenotazione.inizio) }}
                  </div>
                </div>
                <div class="field_info">
                  <div class="field_title">Automezzo</div>
                  <div class="value">
                    {{ prenotazione.automezzo }}
                  </div>
                </div>
              </div>
            </ion-item>
            <!-- Ora inizio -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Data di fine
                </div>
                <div class="value">
                  <ion-datetime
                    name="start_time"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2021"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="prenotazione.data_fine"
                    placeholder="Seleziona data fine"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Ora fine -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Ora fine
                </div>
                <div class="value">
                  <ion-datetime
                    name="end_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    value="1990-02-19T07:43Z"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="prenotazione.ora_fine"
                    placeholder="Seleziona ora di fine"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Luogo destinazione -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Luogo destinazione
                </div>
                <div class="value">
                  <ion-textarea
                    type="text"
                    rows="2"
                    v-model="prenotazione.luogo_destinazione"
                    placeholder="Inserisci il luogo di destinazione"
                    class="custom_input"
                  >
                  </ion-textarea>
                </div>
              </div>
            </ion-item>

            <!-- Note -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Km effettuati</div>
                <div class="value">
                  <ion-input type="number" v-model="prenotazione.km_effettuati" placeholder="Inserisci il totale dei km effettuati"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Note -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Motivazione</div>
                <div class="value">
                  <ion-textarea type="text" rows="3" v-model="prenotazione.motivazione" placeholder="Inserisci la motivazione della prenotazione">
                  </ion-textarea>
                </div>
              </div>
            </ion-item>

            <div class="action">
              <button type="submit" class="btn_prenota">
                Aggiorna prenotazione
              </button>
            </div>
          </ion-list>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  IonList,
  IonItem,
  IonDatetime,
  IonTextarea,
  IonInput,
  IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import { openToast } from "../services/toast";

import apiAutomezzi from "../services/automezzi";

import moment from "moment";

export default defineComponent({
  name: "ModalModificaPrenotazione",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonInput,
    IonIcon,
  },
  setup(props, context) {
    const router = useRouter();
    const successResponse = ref(false);
    //console.log(props.data);
    //prenotazione.value = { ...props.data };

    //Split data e ora inizio per i controlli
    let dataInizio = null;
    let oraInizio = null;
    if (props.data.prenotazioni_automezzi_data_ora_inizio) {
      const inizioTmp = props.data.prenotazioni_automezzi_data_ora_inizio.split(" ");
      dataInizio = inizioTmp[0];
      oraInizio = inizioTmp[1].slice(0, -3);
    }

    //Se avevo già inserito data fine split per dividere in data e orario
    let dataFine = null;
    let oraFine = null;
    if (props.data.prenotazioni_automezzi_data_ora_fine) {
      const fineTmp = props.data.prenotazioni_automezzi_data_ora_fine.split(" ");
      dataFine = fineTmp[0];
      oraFine = fineTmp[1].slice(0, -3);
    } else {
      dataFine = moment().format("YYYY/MM/DD");
      oraFine = moment().format("HH:mm");
    }

    const prenotazione = reactive({
      id: props.data.prenotazioni_automezzi_id,
      inizio: props.data.prenotazioni_automezzi_data_ora_inizio,
      data_inizio: dataInizio,
      ora_inizio: oraInizio,
      data_fine: dataFine,
      ora_fine: oraFine,
      luogo_destinazione: props.data.prenotazioni_automezzi_luogo_destinazione,
      km_effettuati: props.data.prenotazioni_automezzi_km_effettuati,
      motivazione: props.data.prenotazioni_automezzi_motivazione,
      automezzo: `${props.data.automezzi_marca} ${props.data.automezzi_modello}`,
    });

    /**
     * Close modal after form data submit
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        console.log(res);
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY H:i
     */
    function dateFormatWithMinutes(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY, HH:mm");
      } else {
        return "-";
      }
    }

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    function isSameDay(firstDate, secondDate) {
      const first = moment(firstDate).valueOf();
      const second = moment(secondDate).valueOf();
      if (first === second) {
        return true;
      }
      return false;
    }

    function modificaPrenotazione() {
      const data_fine = prenotazione.data_fine ? moment(prenotazione.data_fine).format("YYYY-MM-DD") : "";
      //const ora_fine = prenotazione.ora_fine ? moment(prenotazione.ora_fine).format("HH:mm") : "";
      const ora_fine = prenotazione.ora_fine ? prenotazione.ora_fine : "";

      //Se data fine è < data inizio mostro errore
      if (data_fine && moment(data_fine).isBefore(moment(prenotazione.data_inizio))) {
        openToast("La data di fine non può essere antecedente alla data di inizio", "toast_danger");
        return;
      }
      //Se stessa giornata e ora fine < ora inizio mostro errore
      if (data_fine && ora_fine && isSameDay(prenotazione.data_inizio, data_fine) && ora_fine < prenotazione.ora_inizio) {
        openToast("L'ora di fine non può essere antecedente a quella di inizio", "toast_danger");
        return;
      }
      //Se imposto ora fine forzo la scelta anche della data
      if (!data_fine && ora_fine) {
        openToast("Devi impostare sia la data che l'ora di fine prenotazione", "toast_danger");
        return;
      }

      //const data_ora_fine = `${data_fine} ${ora_fine}`;
      let data_ora_fine = "";
      if (data_fine && ora_fine) {
        data_ora_fine = `${data_fine} ${ora_fine}`;
      } else if (data_fine && !ora_fine) {
        data_ora_fine = `${data_fine} 00:00:00`;
      }

      const data = new FormData();
      data.append("prenotazioni_automezzi_data_ora_fine", data_ora_fine);
      data.append("prenotazioni_automezzi_luogo_destinazione", prenotazione.luogo_destinazione ? prenotazione.luogo_destinazione : "");
      data.append("prenotazioni_automezzi_km_effettuati", prenotazione.km_effettuati ? prenotazione.km_effettuati : "");
      data.append("prenotazioni_automezzi_motivazione", prenotazione.motivazione ? prenotazione.motivazione : "");

      // Display the values
      /* for (const value of data.entries()) {
        console.log(value[0] + ", " + value[1]);
      }
      return; */

      apiAutomezzi
        .editPrenotazione(prenotazione.id, data)
        .then((response) => {
          if (response.data.status == 8) {
            openToast(response.data.message, "toast_danger");
          } else {
            const res = response.data.data[0];
            successResponse.value = true;
            closeModalOnSubmit(successResponse, res);
          }
        })
        .catch((error) => {
          //console.error(error);
          openToast("Errore durante la modifica della prenotazione", "toast_danger");
        })
        .finally(() => {
          //console.log("fine creazione presenza");
        });
    }

    return {
      prenotazione,
      modificaPrenotazione,
      closeModal,
      arrowBackOutline,
      close,
      dateFormatWithMinutes,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
/* New rules */
.page_content {
  width: 100%;
  position: relative;
  min-height: 100%;
  background: #f8fafc;
  padding: 16px;
}

.fields {
  width: 100%;
  border-radius: 4px;
  /*padding: 10px;*/
  padding: 16px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.recap_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
}

.field_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #6b7280;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-end: 0;
  margin-bottom: 4px;
}

ion-datetime,
ion-select {
  max-width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  --padding-start: 8px;
}

ion-input,
ion-textarea {
  --placeholder-color: #6b7280;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  margin-top: 0;
  border: 1px solid #d1d5db;
  border-radius: 4px;
}
.custom_input textarea::placeholder {
  font-size: 5px;
}

.btn_prenota {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  background-color: rgb(22 163 74);
  color: #ffffff;
}

.field_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.field_info:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.field_info .title {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
}
.field_info .value {
  font-size: 14px;
}
</style>
